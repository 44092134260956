#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
}
.router-link-active.router-link-exact-active {
}
